import { FunctionComponent, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import { ROUTES } from "constants/routes";
import { useId } from "react-id-generator";
import AuthProivder from "providers/auth";
import GoogleProvider from "providers/google";
import { PermissionAction, PermissionSubject } from "types/permissions";
import ProtectedRoute from "components/protected-route";
import SettingsProvider from "providers/settings";

const DashboardPage = lazy(() => import("./dashboard"));
const StoragesPage = lazy(() => import("./storages"));
const ClientsPage = lazy(() => import("./clients"));
const OrdersPage = lazy(() => import("./orders"));
const ContractsPage = lazy(() => import("./contracts"));
const ContractPage = lazy(() => import("./contracts/contract"));
const ServicesPage = lazy(() => import("./services"));
const BillingPage = lazy(() => import("./billing"));
const MembersPage = lazy(() => import("./members"));
const SettingsPage = lazy(() => import("./settings"));
const MemberPage = lazy(() => import("./member"));
const InvoicePage = lazy(() => import("./invoice"));
const ServicePage = lazy(() => import("./service"));
const ViewStoragePage = lazy(() => import("./storage/view-storage"));
const ClientPage = lazy(() => import("./client/view-client"));
const ClientCreatePage = lazy(() => import("./client/create-client"));
const ClientEditPage = lazy(() => import("./client/edit-client"));
const EditStoragePage = lazy(() => import("./storage/edit-storage"));
const NewStoragePage = lazy(() => import("./storage/create-storage"));
const DriverLicensesPage = lazy(() => import("./driver-licenses"));
const DriverLicensePage = lazy(() => import("./driver-licenses/driver-license"));
const CarRentalCostsPage = lazy(() => import("./car-rental-costs"));

interface PagesProps {}

const Pages: FunctionComponent<PagesProps> = () => {
  const pages: {
    element: typeof DashboardPage;
    path: string;
    permission: { action: PermissionAction; subject: PermissionSubject };
  }[] = [
    { element: DashboardPage, path: ROUTES.dashboard, permission: { action: "read", subject: "dashboard" } },
    { element: StoragesPage, path: ROUTES.storages, permission: { action: "read", subject: "storages" } },
    { element: ClientsPage, path: ROUTES.clients, permission: { action: "read", subject: "clients" } },
    { element: OrdersPage, path: ROUTES.orders, permission: { action: "read", subject: "orders" } },
    { element: ContractsPage, path: ROUTES.contracts, permission: { action: "read", subject: "contracts" } },
    { element: ContractPage, path: ROUTES.newContract, permission: { action: "create", subject: "contracts" } },
    { element: ContractPage, path: ROUTES.editContract, permission: { action: "update", subject: "contracts" } },
    { element: ServicesPage, path: ROUTES.services, permission: { action: "read", subject: "services" } },
    { element: BillingPage, path: ROUTES.billing, permission: { action: "read", subject: "invoices" } },
    { element: MembersPage, path: ROUTES.members, permission: { action: "read", subject: "members" } },
    { element: SettingsPage, path: ROUTES.settings, permission: { action: "read", subject: "settings" } },
    { element: MemberPage, path: ROUTES.member, permission: { action: "update", subject: "members" } },
    { element: InvoicePage, path: ROUTES.invoice, permission: { action: "update", subject: "invoices" } },
    { element: ServicePage, path: ROUTES.service, permission: { action: "update", subject: "services" } },
    { element: ViewStoragePage, path: ROUTES.storage, permission: { action: "read", subject: "storages" } },
    { element: ClientPage, path: ROUTES.client, permission: { action: "read", subject: "clients" } },
    { element: ClientCreatePage, path: ROUTES.newClient, permission: { action: "create", subject: "clients" } },
    { element: ClientEditPage, path: ROUTES.editClient, permission: { action: "update", subject: "clients" } },
    { element: EditStoragePage, path: ROUTES.editStorage, permission: { action: "update", subject: "storages" } },
    { element: NewStoragePage, path: ROUTES.newStorage, permission: { action: "create", subject: "storages" } },
    { element: DriverLicensesPage, path: ROUTES.driverLicenses, permission: { action: "read", subject: "driver-licenses" } },
    { element: DriverLicensePage, path: ROUTES.driverLicense, permission: { action: "update", subject: "driver-licenses" } },
    { element: CarRentalCostsPage, path: ROUTES.carRentalCosts, permission: { action: "read", subject: "car-rental-costs" } },
  ];
  const ids = useId(pages.length);

  return (
    <SettingsProvider>
      <AuthProivder>
        <GoogleProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                {pages.map((page, i) => (
                  <Route
                    key={ids[i]}
                    element={
                      <ProtectedRoute permission={page.permission}>
                        <page.element />
                      </ProtectedRoute>
                    }
                    path={page.path}
                  />
                ))}
              </Route>
            </Routes>
          </BrowserRouter>
        </GoogleProvider>
      </AuthProivder>
    </SettingsProvider>
  );
};

export default Pages;
