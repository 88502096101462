import classNames from "classnames";
import { FunctionComponent } from "react";

interface HelpTextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hasError?: boolean;
}

export const HELP_TEXT_CLASSNAME = "help-text";

const HelpText: FunctionComponent<HelpTextProps> = ({ hasError, className, ...divProps }) => {
  return (
    <div
      {...divProps}
      className={classNames(className, HELP_TEXT_CLASSNAME, "text-tilps-gray-second text-[12px] mt-1.5", {
        "!text-[#DD4C4C]": hasError,
      })}
    ></div>
  );
};

export default HelpText;
