import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface LoaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Loader: FunctionComponent<LoaderProps> = ({ className, ...divProps }) => {
  const { t } = useTranslation("common");
  return (
    <div className={classNames("flex items-center justify-center", className)} {...divProps}>
      {t("loading")}
    </div>
  );
};

export default Loader;
