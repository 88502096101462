import { FunctionComponent, useMemo, useState } from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import Button from "components/buttons/button";
import { LoginCredentials, useAuth } from "providers/auth";
import * as yup from "yup";
import { LOGIN_EMAIL_INPUT_TEST_ID, LOGIN_PASSWORD_INPUT_TEST_ID, LOGIN_SUBMIT_BUTTON_TEST_ID } from "constants/test/login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/form-input";
import FormPasswordInput from "components/form/form-password-input";
import ErrorMessage from "./error-message";

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { t } = useTranslation("login");
  const { t: formT } = useTranslation("form");
  const { t: commonT } = useTranslation("common");
  const { login } = useAuth();
  const [error, setError] = useState<string>();

  const resolver = useMemo(() => {
    return yupResolver(
      yup
        .object()
        .shape({
          email: yup.string().email(formT("invalidError")).required(formT("requiredError")),
          password: yup.string().required(formT("requiredError")),
        })
        .required()
    );
  }, [formT]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginCredentials>({ resolver, mode: "all", reValidateMode: "onBlur" });

  const onSubmit = async (credentials: LoginCredentials) => {
    const err = await login(credentials);
    if (err) {
      setError(t("invalidCredentials"));
    }
  };

  return (
    <div className="fixed inset-0 overflow-auto p-12 flex bg-[#F9F9F9] text-sm">
      <div className="absolute left-0 top-0 w-full p-12 flex justify-between">
        <Logo />
      </div>
      <div className="m-auto bg-white rounded-lg shadow-[0px_7px_24px_rgba(0,0,0,0.04)] p-12 max-w-[480px] w-full">
        <div className="text-[22px] font-bold mb-2">{t("heading")}</div>
        <div className="text-[13px] text-[#949699] mb-7">{t("subheading")}</div>
        <div className="mb-6">
          <div className="flex text-xs justify-between mb-1.5">
            <div className="opacity-60">{t("emailLabel")}</div>
          </div>
          <FormInput control={control} name="email" data-testid={LOGIN_EMAIL_INPUT_TEST_ID} className="w-full" />
        </div>
        <div className="mb-4">
          <div className="flex justify-between mb-1.5 text-xs">
            <div className="opacity-60">{t("passwordLabel")}</div>
          </div>
          <FormPasswordInput data-testid={LOGIN_PASSWORD_INPUT_TEST_ID} control={control} name="password" className="w-full" />
        </div>

        <Button data-testid={LOGIN_SUBMIT_BUTTON_TEST_ID} onClick={handleSubmit(onSubmit)} variant="primary" className="mt-9">
          {isSubmitting ? commonT("loading") : t("button")}
        </Button>
        {!error ? null : <ErrorMessage className="mt-4" message={error} />}
      </div>
    </div>
  );
};

export default LoginPage;
