import classNames from "classnames";
import { FunctionComponent } from "react";
import HelpText from "./help-text";

export interface InputProps extends React.ComponentProps<"input"> {
  helpText?: string;
  hasError?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  variant?: "filled" | "outlined";
  height?: string;
  inputClassName?: string;
}

const Input: FunctionComponent<InputProps> = ({
  helpText,
  hasError,
  className,
  startAdornment,
  endAdornment,
  variant = "filled",
  height,
  inputClassName,
  type,
  value,
  disabled,
  ...inputProps
}) => {
  return (
    <>
      <div className={classNames("relative", className)}>
        {!!startAdornment && <div className="absolute inset-y-0 left-0 flex items-center pl-3">{startAdornment}</div>}
        <input
          disabled={disabled}
          value={value || ""}
          onKeyDown={(e) => {
            if (type === "number") {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }
          }}
          type={type}
          {...inputProps}
          className={classNames(
            "border outline-none text-xs transition px-3.5 rounded-md w-full",
            "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
            height,
            {
              "text-black/50": disabled,
              "border-red-500": hasError,
              "border-[#EFEFEF] focus:border-tilps-yellow": !hasError,
              "bg-[#F8F8F8]": variant === "filled",
              "pl-9": !!startAdornment,
              "pr-9": !!endAdornment,
              "h-10": !height,
            },
            inputClassName
          )}
        />
        {!!endAdornment && <div className="absolute inset-y-0 right-0 flex items-center pr-3">{endAdornment}</div>}
      </div>
      {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
    </>
  );
};

export default Input;
