import { Control, Controller, FieldValues, Path } from "react-hook-form";
import PasswordInput, { PasswordInputProps } from "../password-input";

export interface FormInputProps<T extends FieldValues> extends PasswordInputProps {
  control: Control<T, any>;
  name: Path<T>;
}

const FormPasswordInput = <T extends FieldValues>({ name, control, helpText, ...inputProps }: FormInputProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error, isTouched }, formState: { isSubmitted } }) => (
      <PasswordInput
        helpText={isSubmitted && !!error ? error?.message : helpText}
        hasError={isSubmitted && !!error}
        {...field}
        {...inputProps}
        ref={null}
      />
    )}
  />
);

export default FormPasswordInput;
