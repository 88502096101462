import classNames from "classnames";
import { FunctionComponent } from "react";
import ButtonBase from "./button-base";

interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: "primary" | "secondary" | "secondary-yellow" | "disabled" | "tertiary" | "custom";
  height?: string;
}

const Button: FunctionComponent<ButtonProps> = ({ variant = "primary", height, className, children, ...divProps }) => {
  return (
    <ButtonBase
      {...divProps}
      className={classNames(className, "h-10 rounded-full text-sm", height, {
        "bg-tilps-yellow hover:bg-tilps-hover-yellow font-bold": variant === "primary",
        "border-tilps-gray-third border text-tilps-gray-second hover:bg-tilps-gray-second/10": variant === "secondary",
        "border-tilps-yellow border text-tilps-yellow hover:bg-tilps-yellow/10": variant === "secondary-yellow",
        "bg-tilps-yellow hover:bg-tilps-hover-yellow bg-opacity-10 hover:bg-opacity-10 text-tilps-yellow": variant === "tertiary",
        "bg-tilps-gray-third text-white font-bold pointer-events-none": variant === "disabled",
      })}
    >
      {children}
    </ButtonBase>
  );
};

export default Button;
