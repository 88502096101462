export const ROUTES = {
  dashboard: "/",
  storages: "/storages",
  storage: "/storages/:id",
  editStorage: "/storages/:id/edit",
  newStorage: "/storages/new",
  clients: "/clients",
  client: "/clients/:id",
  editClient: "/clients/:id/edit",
  newClient: "/clients/new",
  orders: "/orders",
  contracts: "/contracts",
  newContract: "/contracts/new",
  editContract: "/contracts/:id/edit",
  services: "/services",
  billing: "/billing",
  members: "/members",
  notifications: "/notifications",
  settings: "/settings",
  login: "/login",
  member: "/members/:id",
  invoice: "/billing/:id",
  service: "/services/:id",
  driverLicenses: "/driver-licenses",
  driverLicense: "/driver-licenses/:id",
  carRentalCosts: "/car-rental-costs",
};

export const NEW_KEY = "new";
