import classNames from "classnames";
import { FunctionComponent } from "react";

interface ButtonBaseProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ButtonBase: FunctionComponent<ButtonBaseProps> = ({ className, children, ...divProps }) => {
  return (
    <div
      {...divProps}
      className={classNames(
        "flex justify-center items-center active:scale-95 transform transition cursor-pointer select-none",
        className
      )}
    >
      {children}
    </div>
  );
};

export default ButtonBase;
