import { FunctionComponent } from "react";
import Pages from "./pages/pages";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "utils/query-client";

interface AppProps {}

const App: FunctionComponent<AppProps> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Pages />
    </QueryClientProvider>
  );
};

export default App;
