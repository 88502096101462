import classNames from "classnames";
import { FunctionComponent } from "react";

interface ErrorMessageProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  message: string;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ className, message, ...rest }) => {
  return (
    <div
      {...rest}
      className={classNames(className, "py-2 px-4 rounded-md font-medium text-sm text-center bg-red-100 text-red-500")}
    >
      {message}
    </div>
  );
};

export default ErrorMessage;
