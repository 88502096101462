import { API_URL } from "constants/api";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

type OrdersCountResponse = { count: number };

export const useNewOrdersCount = () => {
  const [count, setCount] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    fetch(`${API_URL}admin/orders/new/count`, { headers: { Authorization: `bearer ${user?.token}` } })
      .then((x) => x.json())
      .then((res: OrdersCountResponse) => {
        setCount(res.count);
      });
  }, []);

  return count;
};
