import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

interface GoogleProviderProps extends PropsWithChildren {}

const GoogleContext = createContext<{ init: () => void; isLoaded: boolean }>({} as any);

export const useGoogle = () => useContext(GoogleContext);

const GoogleProvider: FunctionComponent<GoogleProviderProps> = ({ children }) => {
  const [init, setInit] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (window as any).initMap = () => setIsLoaded(true);
  }, []);

  return (
    <GoogleContext.Provider value={{ isLoaded, init: () => setInit(true) }}>
      {!init ? null : (
        <Helmet>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initMap`}
          ></script>
        </Helmet>
      )}
      {children}
    </GoogleContext.Provider>
  );
};

export default GoogleProvider;
