import { Member } from "./member";

export enum UserRole {
  Admin = "ROLE_ADMIN",
  Editor = "ROLE_EDITOR",
}

export const USER_ROLES = [UserRole.Admin, UserRole.Editor];

export interface UserTokens {
  token: string;
  tokenExpiration: number;
  refreshToken: string;
}

export interface User extends UserTokens, Member {}

export interface UserSettingsFormFields extends Omit<User, "id" | "tokenExpiration" | "token" | "refreshToken"> {
  language: string;
  timeZone: string;
  password?: string;
  newPassword?: string;
}
