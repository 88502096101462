import { FunctionComponent } from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as StoragesIcon } from "assets/icons/storages.svg";
import { ReactComponent as ClientsIcon } from "assets/icons/clients.svg";
import { ReactComponent as OrdersIcon } from "assets/icons/orders.svg";
import { ReactComponent as ContractsIcon } from "assets/icons/contracts.svg";
import { ReactComponent as ServicesIcon } from "assets/icons/services.svg";
import { ReactComponent as BillingIcon } from "assets/icons/billing.svg";
import { ReactComponent as MembersIcon } from "assets/icons/members.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as DriverLicenseIcon } from "assets/icons/driver-license.svg";
import { ReactComponent as CarRentalCostsIcon } from "assets/icons/car-rental-costs.svg";

import { ROUTES } from "constants/routes";
import MenuItem, { Link } from "./menu-item";
import Scrollbar from "components/scrollbar/scrollbar";
import { useMeasure } from "react-use";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "providers/auth";
import OrdersCountBadge from "./orders-count-badge";
import { canUser } from "utils/permissions";

interface MenuProps {}

const Menu: FunctionComponent<MenuProps> = () => {
  const { t } = useTranslation("menu");
  const { logout, user } = useAuth();

  const topLinks: Link[] = [
    { label: t("dashboard"), icon: DashboardIcon, path: ROUTES.dashboard, canAccess: canUser(user, "read", "dashboard") },
    {
      label: t("storages"),
      icon: StoragesIcon,
      path: ROUTES.storages,
      canAccess: canUser(user, "read", "storages"),
    },
    {
      label: t("clients"),
      icon: ClientsIcon,
      path: ROUTES.clients,
      canAccess: canUser(user, "read", "clients"),
    },
    {
      label: t("orders"),
      icon: OrdersIcon,
      path: ROUTES.orders,
      badge: <OrdersCountBadge />,
      canAccess: canUser(user, "read", "orders"),
    },
    {
      label: t("contracts"),
      icon: ContractsIcon,
      path: ROUTES.contracts,
      canAccess: canUser(user, "read", "contracts"),
    },
    {
      label: t("services"),
      icon: ServicesIcon,
      path: ROUTES.services,
      canAccess: canUser(user, "read", "services"),
    },
    {
      label: t("billing"),
      icon: BillingIcon,
      path: ROUTES.billing,
      canAccess: canUser(user, "read", "invoices"),
    },
    {
      label: t("members"),
      icon: MembersIcon,
      path: ROUTES.members,
      canAccess: canUser(user, "read", "members"),
    },
    {
      label: t("driverLicenses"),
      icon: DriverLicenseIcon,
      path: ROUTES.driverLicenses,
      canAccess: canUser(user, "read", "driver-licenses"),
    },
    {
      label: t("carRentalCosts"),
      icon: CarRentalCostsIcon,
      path: ROUTES.carRentalCosts,
      canAccess: canUser(user, "read", "car-rental-costs"),
    },
  ];
  const bottomLinks: Link[] = [
    {
      label: t("settings"),
      icon: SettingsIcon,
      path: ROUTES.settings,
      canAccess: canUser(user, "read", "settings"),
    },
    { label: t("logout"), icon: LogoutIcon, path: "#", customHandler: logout, canAccess: true },
  ];

  const navigate = useNavigate();
  const [ref, { height }] = useMeasure();

  return (
    <div className="h-full w-[248px] bg-white text-sm flex flex-col z-10 relative">
      <div className="pl-6 pt-10 pb-6">
        <Logo onClick={() => navigate(ROUTES.dashboard)} />
      </div>
      <div ref={ref as any} className="flex-grow overflow-hidden -mr-1.5">
        <Scrollbar autoHide={false} className="h-full">
          <div style={{ minHeight: height }} className="flex flex-col pt-3 pr-1.5">
            <div className="space-y-3.5">
              {topLinks.map((link) => (!link.canAccess ? null : <MenuItem link={link} key={`menu-link-${link.path}`} />))}
            </div>
            <div className="flex-grow flex pb-6 mt-3.5">
              <div className="mt-auto w-full space-y-3.5">
                {bottomLinks.map((link) => (!link.canAccess ? null : <MenuItem link={link} key={`menu-link-${link.path}`} />))}
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default Menu;
