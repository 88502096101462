import { FunctionComponent } from "react";
import { useNewOrdersCount } from "./use-new-orders-count";

interface CountBadgeProps {}

const CountBadge: FunctionComponent<CountBadgeProps> = () => {
  const count = useNewOrdersCount();

  return !count ? null : (
    <div className="w-4 h-4 font-medium text-white rounded-full flex items-center justify-center bg-[#E95C5C] text-[11px] mr-5">
      {count}
    </div>
  );
};

export default CountBadge;
