import { Control, Controller, FieldValues, Path } from "react-hook-form";
import Input, { InputProps } from "../input";

export interface FormInputProps<T extends FieldValues> extends InputProps {
  control: Control<T, any>;
  name: Path<T>;
}

const FormInput = <T extends FieldValues>({ name, control, helpText, ...inputProps }: FormInputProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error }, formState: { isSubmitted } }) => (
      <Input
        helpText={isSubmitted && !!error ? error?.message : helpText}
        hasError={isSubmitted && !!error}
        {...field}
        {...inputProps}
        ref={null}
      />
    )}
  />
);

export default FormInput;
