import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import lt from "./locales/lt.json";
import LanguageDetector from "i18next-browser-languagedetector";
import "dayjs/locale/lt";
import dayjs from "dayjs";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en,
      lt,
    },
    fallbackLng: "en",
    supportedLngs: ["en", "lt"],
    interpolation: {
      escapeValue: false,
    },
  });

dayjs.locale(i18n.language);

i18n.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});
