import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

interface Settings {
  timeZone: string;
  language: string;
}

const SettingsContext = createContext<{ settings: Settings; setSettings: (val: Settings) => void }>({} as any);

export const useSettings = () => useContext(SettingsContext);

interface SettingsProviderProps extends PropsWithChildren {}

const defaultSettings: Settings = { language: "en", timeZone: "Europe/Vilnius" };

const SettingsProvider: FunctionComponent<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useLocalStorage<Settings>("settings", defaultSettings);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(settings?.language ?? defaultSettings.language);
    dayjs.tz.setDefault(settings?.timeZone ?? defaultSettings.timeZone);
  }, [settings]);

  return (
    <SettingsContext.Provider value={{ settings: settings || defaultSettings, setSettings }}>{children}</SettingsContext.Provider>
  );
};

export default SettingsProvider;
