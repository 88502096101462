import { API_URL } from "constants/api";
import { USER } from "constants/auth";
import dayjs from "dayjs";
import LoginPage from "pages/login/login";
import { FunctionComponent, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { ApiResponse } from "types/api";
import { User, UserRole, UserTokens } from "types/user";

interface AuthProivderProps extends PropsWithChildren {}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface AuthState {
  user: User | undefined;
  setUser: (user: User) => void;
  login: (credentials: LoginCredentials) => Promise<void | string>;
  logout: () => void;
  isReady: boolean;
}

const AuthContext = createContext<AuthState>({} as any);

export const useAuth = () => useContext(AuthContext);

export type LoginResponse = UserTokens | { error: string };

const AuthProivder: FunctionComponent<AuthProivderProps> = ({ children }) => {
  const [user, _setUser] = useState<User>();
  const [isReady, setIsReady] = useState(false);

  const getMe = async (token: string) => {
    const me: ApiResponse<Pick<User, "firstName" | "lastName" | "email" | "role">> = await fetch(`${API_URL}admin/me`, {
      headers: { authorization: `bearer ${token}` },
    }).then((x) => x.json());

    return "id" in me ? me : null;
  };

  const login: AuthState["login"] = async ({ email, password }) => {
    try {
      const res: LoginResponse = await fetch(`${API_URL}admin/auth/login`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
      }).then((x) => x.json());

      if ("error" in res) {
        return res.error;
      } else {
        const { token, refreshToken, tokenExpiration } = res;
        const me = await getMe(token);
        const user = {
          ...(me ?? { id: 1, email, firstName: "", lastName: "", role: UserRole.Admin }),
          token,
          refreshToken,
          tokenExpiration,
        } as User;
        _setUser(user);
        localStorage.setItem(USER, JSON.stringify(user));
      }
    } catch (er) {
      return "error";
    }
  };

  const logout = () => {
    _setUser(undefined);
    localStorage.removeItem(USER);
  };

  useEffect(() => {
    const checkUser = async () => {
      try {
        const userString = localStorage.getItem(USER);
        if (userString) {
          let parsedUser = JSON.parse(userString);
          let tokens: UserTokens = { ...parsedUser };

          if (tokens.tokenExpiration < dayjs().unix()) {
            const result: ApiResponse<UserTokens> = await fetch(`${API_URL}admin/auth/refresh`, {
              method: "POST",
              body: JSON.stringify({ refreshToken: tokens.refreshToken }),
              headers: { "Content-Type": "application/json" },
            }).then((x) => x.json());

            if ("token" in result) {
              tokens = result;
            }
          }

          const me = await getMe(tokens.token);
          setUser({ ...parsedUser, ...tokens, ...(me ?? {}) });
        }
      } catch (er) {}
      setIsReady(true);
    };

    checkUser();
  }, []);

  const setUser: AuthState["setUser"] = (newUser) => {
    _setUser(newUser);
    localStorage.setItem(USER, JSON.stringify(newUser));
  };

  return (
    <AuthContext.Provider value={{ login, logout, user, isReady, setUser }}>
      {!isReady ? null : !user ? <LoginPage /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProivder;
