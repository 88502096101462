import { FunctionComponent, ReactElement } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

export interface Link {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;
  customHandler?: () => void;
  badge?: ReactElement;
  canAccess: boolean;
}

interface MenuItemProps {
  link: Link;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ link }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSelected = link.path === "/" ? link.path === location.pathname : location.pathname.startsWith(link.path);
  return (
    <div
      onClick={() => (link.customHandler ? link.customHandler() : navigate(link.path))}
      key={`menu-link-${link.path}`}
      className={classNames("flex items-center relative select-none pl-6 h-10", {
        "cursor-pointer hover:bg-gray-100": !isSelected,
      })}
    >
      <link.icon
        className={classNames("mr-2 flex-shrink-0", {
          "text-[#D9D9D9]": !isSelected,
          "text-tilps-yellow": isSelected,
        })}
      />
      <div className="flex justify-between items-center flex-grow">
        <div
          className={classNames("font-medium", {
            "opacity-60": !isSelected,
            "font-bold text-tilps-yellow": isSelected,
          })}
        >
          {link.label}
        </div>
        {link.badge}
      </div>
      {!isSelected ? null : <div className="w-1 h-[34px] rounded-l-md bg-tilps-yellow absolute right-0"></div>}
    </div>
  );
};

export default MenuItem;
