import { ComponentProps, FunctionComponent, useState } from "react";
import Input from "./input";
import classNames from "classnames";
import { ReactComponent as VisibilityOnIcon } from "assets/icons/visibility-on.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/icons/visibility-off.svg";

export interface PasswordInputProps extends ComponentProps<typeof Input> { }

const PasswordInput: FunctionComponent<PasswordInputProps> = ({ className, ...inputProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classNames("relative", className)}>
      <Input
        {...inputProps}
        type={!showPassword ? "password" : "text"}
        className="w-full"
        endAdornment={
          <div className="cursor-pointer select-none text-tilps-gray-second" onClick={() => setShowPassword((prev) => !prev)}>
            {showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          </div>
        }
      />
    </div>
  );
};

export default PasswordInput;
