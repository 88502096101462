import { FunctionComponent, Suspense, useState } from "react";
import Menu from "./menu";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Loader from "./loader";

interface LayoutProps {}

const Layout: FunctionComponent<LayoutProps> = () => {
  const { t } = useTranslation("common");

  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="fixed inset-0 flex flex-col md:flex-row">
      <div className="flex md:hidden justify-between items-center px-5 py-3 border-b">
        <Logo className="w-[80px]" />
        <MenuIcon className="w-5 h-5 cursor-pointer" onClick={() => setOpenMenu(true)} />
      </div>
      <div
        onClick={() => setOpenMenu(false)}
        className={classNames("fixed md:static flex inset-0 z-50 transition", {
          "bg-black/40 pointer-events-auto": openMenu,
          "bg-black/0 pointer-events-none": !openMenu,
        })}
      >
        <div
          className={classNames("h-full transform relative transition pointer-events-auto bg-white md:translate-x-0", {
            "translate-x-0": openMenu,
            "-translate-x-full": !openMenu,
          })}
        >
          <Menu />
          <CloseIcon className="md:hidden absolute right-2 top-2 opacity-30 w-5 h-5 z-10 cursor-pointer" />
        </div>
      </div>
      <div className="flex-grow overflow-auto p-8 bg-[#F9F9F9] relative">
        <Suspense fallback={<Loader className="absolute inset-0"></Loader>}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default Layout;
