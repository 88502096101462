import { User, UserRole } from "types/user";
import { Permissions, PermissionAction, PermissionSubject } from "types/permissions";

const AdminPermissions: Permissions = {
  dashboard: ["all"],
  storages: ["all"],
  clients: ["all"],
  orders: ["all"],
  contracts: ["all"],
  invoices: ["all"],
  services: ["all"],
  members: ["all"],
  settings: ["all"],
  "driver-licenses": ["all"],
  "car-rental-costs": ["all"],
  "user-role": ["all"],
};

const EditorPermissions: Permissions = {
  dashboard: [],
  storages: ["read"],
  clients: ["read"],
  orders: ["read"],
  contracts: ["read"],
  invoices: [],
  services: ["read"],
  members: [],
  settings: ["read"],
  "driver-licenses": ["read"],
  "car-rental-costs": [],
  "user-role": ["read"],
};

const permissions = {
  [UserRole.Admin]: AdminPermissions,
  [UserRole.Editor]: EditorPermissions,
} as const;

export const canRole = (role: UserRole, action: PermissionAction, subject: PermissionSubject) => {
  return permissions[role][subject].includes(action) || permissions[role][subject].includes("all");
};

export const canUser = (user: User | undefined, action: PermissionAction, subject: PermissionSubject) => {
  return !user ? false : canRole(user.role, action, subject);
};
