import { ROUTES } from "constants/routes";
import { useAuth } from "providers/auth";
import { FunctionComponent, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { PermissionAction, PermissionSubject } from "types/permissions";
import { UserRole } from "types/user";
import { canUser } from "utils/permissions";

interface ProtectedRouteProps extends PropsWithChildren {
  permission: { action: PermissionAction; subject: PermissionSubject };
}

const fallbackRoutes = {
  [UserRole.Admin]: ROUTES.dashboard,
  [UserRole.Editor]: ROUTES.storages,
};

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ permission, children }) => {
  const { user } = useAuth();

  const fallbackRoute = !user ? "/" : fallbackRoutes[user.role];

  return canUser(user, permission.action, permission.subject) ? <>{children}</> : <Navigate to={fallbackRoute} />;
};

export default ProtectedRoute;
